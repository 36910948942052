import React from "react"
import styled from "styled-components"

import Page from "../../components/page"
import PageHero from "../../components/page-hero"
import { TwoColumnsGrid } from "../../components/containers"
import Image from "../../components/image"

import BooksData from "../../content/books.json"

const BooksHeader = () => (
  <PageHero>
    <div style={{ width: "100%", maxWidth: "1160px" }}>
      <section>
        <h1 className="heading" style={{ margin: 0 }}>
          Risorse utili per imparare ad investire con successo
        </h1>
      </section>
    </div>
  </PageHero>
)

const BookTile = styled.div`
  box-shadow: 0 0 0 1px rgba(104, 95, 116, 0.21);
  border-radius: 3px;
  overflow: hidden;
`

const BookCoverContainer = styled.div`
  width: 100%;

  .container {
    position: relative;
    padding: 24px;
  }

  .clipped-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 32px;
    z-index: 0;
    position: absolute;

    background-color: #fafafa;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
  }

  .cover {
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
`

const BookInfoContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const BooksSection = ({ resources }) => {
  return resources.map(resource => {
    const {
      cover,
      coverBackgroundColor,
      title,
      author,
      description,
      url,
    } = resource
    return (
      <BookTile>
        <BookCoverContainer>
          <div className="container">
            <div
              className="clipped-background"
              style={{ backgroundColor: coverBackgroundColor }}
            />
            <div className="cover">
              <Image
                source={cover}
                alt={title}
                style={{
                  objectPosition: "center center",
                  objectFit: "scale-down",
                }}
              />
            </div>
          </div>
        </BookCoverContainer>
        <BookInfoContainer>
          <h3 className="title">{title}</h3>
          <h4 className="subtitle">{author}</h4>
          <p>{description}</p>
          <a
            href={url}
            alt={`Compra '${title}' su Amazon`}
            className="button-secondary"
            rel="noopener noreferrer"
            target="_blank"
          >
            Compralo su Amazon
          </a>
        </BookInfoContainer>
      </BookTile>
    )
  })
}

const BooksSections = () => {
  return BooksData.map(({ title, resources }) => (
    <section>
      <h2>{title}</h2>
      <TwoColumnsGrid>
        <BooksSection resources={resources} />
      </TwoColumnsGrid>
    </section>
  ))
}

const BooksPage = () => (
  <Page title="Libri" heroContent={<BooksHeader />}>
    <BooksSections />
    <section>
      <p>
        <small>
          NOTA: I link su questa pagina sono tutti link d'affiliazione verso
          Amazon Italia. Usando questi link ci aiuterete a supportare lo
          sviluppo dei contenuti su <em>Investitori Ribelli</em>, inclusi blog e
          podcast.
        </small>
      </p>
    </section>
  </Page>
)

export default BooksPage
