import React from "react"
import styled from "styled-components"

import Image from "../components/image"

const PageHeroContainer = styled.div`
  background-color: #fafafa;
  width: 100%;

  z-index: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & .page__hero-image {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
    position: absolute !important;
  }

  & .page__hero-alignment-top {
    & img {
      object-position: top center !important;
    }
  }

  & .page__hero-alignment-center {
    & img {
      object-position: center center !important;
    }
  }

  & .page__hero-alignment-bottom {
    & img {
      object-position: bottom center !important;
    }
  }

  & .page__hero-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    z-index: 2;
    position: absolute !important;

    background-color: rgba(0, 0, 0, 0.5);
  }

  & .page__hero-content {
    z-index: 3;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 64px 24px;

    & section {
      margin: 0;
    }
  }

  min-height: 400px;

  @media (max-width: 960px) {
    min-height: unset;
  }
`

const PageHero = ({ cover, coverAlignment = "top", title, children }) => {
  const coverClassName = `page__hero-image page__hero-alignment-${coverAlignment}`
  return (
    <PageHeroContainer>
      {cover && <Image className={coverClassName} alt={title} source={cover} />}
      {cover && <div className="page__hero-overlay" />}
      <div className="page__hero-content">{children}</div>
    </PageHeroContainer>
  )
}

export default PageHero
